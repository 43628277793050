.ComingSoon {
  text-align: center;
  animation: change-background 10s linear infinite alternate;
  background: rgb(23, 125, 29);
  left: 0;
  top: 0;
  min-height: 100vh;
  min-width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(8px + 2vmin);
  color: white;
}

.App {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  min-width: 100vw;
}

@keyframes change-background {
  0% {
    background: rgb(23, 125, 29);
  }
  50% {
    background: rgb(11, 112, 121);
  }
  100% {
    background: rgb(23, 125, 29);
  }
}

/*   0% {
    background: #fac13b;
  }
  50% {
    background: #15ab63;
  }
  100% {
    background: #006a25;
  }
   */

.background {
  animation: change-background 4s linear infinite alternate;
  background: rgb(23, 125, 29);
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
}


/* .App-logo {
  height: 40vmin;
  pointer-events: none;
}  */

/* .App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
} */


/* @media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
} */


/* .App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }

  
} */